<script setup lang="ts">
import type { Summary } from '@/types/accounts.js'
import type { Reply } from '@/types/api.js'
import useFormatterPrices from '@/composables/formatter-prices.js'

const nuxtApp = useNuxtApp()
const route = useRoute()
const globalStore = useGlobal()

const payments = reactive({
  due: {
    amount: 0,
    total: 0,
  },
  domicile: {
    amount: 0,
    total: 0,
  },
})

async function getResume() {
  try {
    const response = await nuxtApp.$urlEfici.get<Reply<{ summary: Summary[], count: number }>>(
      `${nuxtApp.$endpoint.resume.getPlace.url}${route.params.id}`,
    )

    if (response.data.error) {
      globalStore.placeDueShow = false
      throw new Error(response.data.message)
    }

    const summaries = response.data.data.summary
    const COMMISION_CONCEPT = 'Commission'
    const DOMICILE_CONCEPT = 'Domicile'

    summaries.forEach((summary) => {
      if (!summary.paid) {
        if (summary.concepto === COMMISION_CONCEPT) {
          payments.due.amount += 1
          payments.due.total += summary.totalCommission
        }
        else if (summary.concepto === DOMICILE_CONCEPT) {
          payments.domicile.amount += 1
          payments.domicile.total += summary.totalCommission
        }
        globalStore.placeDueShow = true
      }
    })
  }
  catch (error) {
    console.error((error as Error).message)
    return error
  }
}

onMounted(() => {
  if (route.params.id && route.path.includes('places')) {
    getResume()
  }
  else {
    globalStore.placeDueShow = false
  }
})
</script>

<template>
  <div
    v-if="payments.due.amount > 0"
    class="fixed bottom-0 left-0 right-0 bg-[#fbdea5] shadow-lg z-50 flex flex-col justify-center items-center"
  >
    <div class="flex items-center justify-center">
      <NuxtImg src="/assets/templates/warning.png" class="size-16 -mt-7" />
    </div>
    <p class="mb-0 text-base font-semibold">
      Gracias por usar nuestros servicios
    </p>
    <div class="pb-4 pt-1 text-center">
      <p>
        Tienes <span class="font-bold text-[15px]"> {{ payments.due.amount + payments.domicile.amount }}  Cuenta</span>
        Pendientes por Pagar por un Valor Total de
        <span class="font-bold text-[15px]"> {{ useFormatterPrices(payments.due.total + payments.domicile.total) }}</span>
      </p>
    </div>
  </div>
</template>
